// slider animations
(function() {
    var umbrella = document.getElementById( 'geba-umbrella-group' );

    if ( umbrella !== null ) {
        var simpler = document.getElementById( 'geba-umbrella-simpler' );
        var perfect_coverage = document.getElementById( 'geba-umbrella-perfect-coverage' );

        run_anim();

        n2ss.ready(2, function( slider, sliderElement ) {
            sliderElement.on('mainAnimationStart',
                function(e, animation, previousSlideIndex, currentSlideIndex) {
                    simpler.classList.remove( 'anim' );
                    perfect_coverage.classList.remove( 'anim' );
                });

            sliderElement.on( 'mainAnimationComplete',
                function( e, animation, previousSlideIndex, currentSlideIndex ) {
                    run_anim();
                });
        });

        function run_anim() {
            anime({
                targets: '#geba-umbrella-moving',
                translateY: [ 670, 0 ],
                easing: 'linear',
                duration: 2000,
                loop: false,
                delay: 1000
            });

            anime({
                targets: '#geba-umbrella-top',
                translateY: [ 669, 0 ],
                easing: 'linear',
                duration: 2000,
                loop: false,
                delay: 1000
            });

            anime({
                targets: '#geba-umbrella-cloud',
                translateX: [ -330, 0 ],
                opacity: [ 0.45, 1 ],
                easing: 'linear',
                duration: 7000,
                loop: false
            });

            anime({
                targets: '#geba-umbrella-legos',
                translateY: [ -300, 0 ],
                easing: 'linear',
                duration: 10000,
                loop: false
            });

            anime({
                targets: '#geba-umbrella-putting-together',
                opacity: [ 0, 1 ],
                easing: 'linear',
                duration: 1000,
                loop: false,
                delay: 3000
            });

            anime({
                targets: '#geba-umbrella-couldnt-be',
                opacity: [ 0, 1 ],
                easing: 'linear',
                duration: 300,
                loop: false,
                delay: 5500
            });

            anime({
                targets: '#geba-umbrella-toothbrush',
                opacity: [ 0, 1 ],
                translateY: [ -100, 0 ],
                easing: 'linear',
                duration: 250,
                loop: false,
                delay: 6800
            });

            anime({
                targets: '#geba-umbrella-glasses',
                opacity: [ 0, 1 ],
                translateY: [ -30, 0 ],
                easing: 'linear',
                duration: 500,
                loop: false,
                delay: 7050
            });

            simpler.classList.add( 'anim' );
            perfect_coverage.classList.add( 'anim' );
        }

    }

})();
