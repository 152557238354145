var applyQuickDental = function(quickForm){
    var calculationTree = {
        "Standard":{
            "Member Only": {
                "Bi-Weekly":"13",
                "Monthly":"28.17",
                "Quarterly":"84.50",
                "Semi-Annually":"169",
                "Annually":"338"
            },
            "Member + One":{
                "Bi-Weekly":"25",
                "Monthly":"54.17",
                "Quarterly":"162.50",
                "Semi-Annually":"325",
                "Annually":"650"
            },
            "Member + Family":{
                "Bi-Weekly":"32",
                "Monthly":"69.33",
                "Quarterly":"208.00",
                "Semi-Annually":"416",
                "Annually":"832"
            }
        },
        "Enhanced":{
            "Member Only":{
                "Bi-Weekly":"22",
                "Monthly":"47.67",
                "Quarterly":"143",
                "Semi-Annually":"286",
                "Annually":"572"
            },
            "Member + One":{
                "Bi-Weekly":"41",
                "Monthly":"88.83",
                "Quarterly":"266.50",
                "Semi-Annually":"533",
                "Annually":"1066"
            },
            "Member + Family":{
                "Bi-Weekly":"59",
                "Monthly":"127.83",
                "Quarterly":"383.50",
                "Semi-Annually":"767",
                "Annually":"1534"
            }
        }
    }
    var quickDental = quickForm;
    //hide submit
    //quickDental.querySelector("button[type='submit']").style.display = "none";
    //grab all inputs
    quickDental.addEventListener("change", function(event){
        var orgSelect = document.getElementById("field_mbkyd");
        //console.log(orgSelect.value);
        if(orgSelect.value !== "Central Intelligence Agency (CIA)" && 
        orgSelect.value !== "Defense Intelligence Agency (DIA)"){
            document.getElementById("field_auv7v-0").disabled = true;
        }else{
            document.getElementById("field_auv7v-0").disabled = false;
        }
        var selectedAnswers = [];
        var outputElement = quickDental.querySelector(".frm_total_big input");
        var form = quickDental
        var outputValue = "";
        //grab all selected answers
        for ( var i = 0; i < form.elements.length; i++ ) {
            var e = form.elements[i];
            if(e.name && e.type != "hidden" && e.name !="frm_verify" && e.value != "" && e.value != "N/A" && !e.value.startsWith('$')){
                if(
                    (e.selected || e.checked) ||
                    (e.type != "select" && e.type != "radio")
                    ){
                        // the "e" left over are all the answers selected in order
                        
                        //go thru the calculation tree and compare values
                        //console.log(e.value);
                        selectedAnswers.push(e);
                    }
                }
            }
            var performCalculation = function(tree, testKey){
                //console.log(selectedAnswers);
                for(var selectedElement of selectedAnswers){
                    //console.log(selectedElement);
                    //console.log({testKey, tree, "value": selectedElement.value})
                    var treeKeys = Object.keys(tree)
                    for(var key of treeKeys){
                        console.log({key, "value" :selectedElement.value});
                        if(key === selectedElement.value){
                            if(typeof tree[key] === "object"){
                                //console.log({"tree":tree[key], key});
                                performCalculation(tree[key], key)
                            }else{
                                if(tree[key] !== ""){
                                    outputValue = tree[key];
                                }else{
                                    outputValue = "N/A";
                                }
                                break;
                            }
                        }
                    }
                    if(outputValue !== ""){
                        break;
                    }
                }
            }
            performCalculation(calculationTree, "first");
            //console.log(outputValue);
            //console.log(outputElement);
            outputElement.value = outputValue === "Null" || outputValue === "" ? "N/A" : "$" + outputValue;
            //console.log(selectedAnswers);
            //var queryString = kvpairs.join("&");
            //console.log(selectedAnswers);
            //find output element
            
            
        });
    var inputs = quickDental.querySelectorAll(`input:not([disabled]):not([type='submit']),
    select:not([disabled]):not([type='submit'])`)
    console.log(inputs);
}
//Dental quick calculations
//grab container
var quickDental = document.querySelector("#frm_form_8_container form");
if(quickDental){
    applyQuickDental(quickDental);
}
    