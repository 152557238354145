// slider animations
(function( $ ) {
    var corp = document.getElementById( 'geba-corp-group' );

    if ( corp !== null ) {
        var pathBirdRight = anime.path( '#geba-corp-bird-right > path' );
        var pathBirdLeft = anime.path( '#geba-corp-bird-left > path' );
        var pathLeafRight = anime.path( '#geba-corp-right-leaf > path' );
        var pathLeafLeft = anime.path( '#geba-corp-left-leaf > path' );

        run_anim();

        n2ss.ready(2, function( slider, sliderElement ) {
            sliderElement.on('mainAnimationStart',
                function(e, animation, previousSlideIndex, currentSlideIndex) {
                    document.getElementById('geba-corp-balloon').classList.remove('anim');
                });

            // re-run anim on slide change
            sliderElement.on( 'mainAnimationComplete',
                function( e, animation, previousSlideIndex, currentSlideIndex ) {
                    run_anim();
                });
        });

        function run_anim() {
            // left leaf
            anime({
                targets: '#geba-corp-left-leaf .graphic',
                translateX: pathLeafLeft('x'),
                translateY: pathLeafLeft('y'),
                easing: 'easeInOutExpo',
                duration: 10000,
                loop: false
            });

            // left bird
            anime({
                targets: '#geba-corp-bird-left .graphic',
                translateX: pathBirdLeft('x'),
                translateY: pathBirdLeft('y'),
                easing: 'easeOutQuart',
                duration: 10000,
                loop: false
            });

            // right leaf
            anime({
                targets: '#geba-corp-right-leaf .graphic',
                translateX: pathLeafRight('x'),
                translateY: pathLeafRight('y'),
                easing: 'easeInOutCubic',
                duration: 10000,
                loop: false
            });

            // right bird
            anime({
                targets: '#geba-corp-bird-right .graphic',
                translateX: pathBirdRight('x'),
                translateY: pathBirdRight('y'),
                easing: 'easeOutSine',
                duration: 10000,
                loop: false
            });

            // you
            anime({
                targets: '#geba-corp-top-text',
                translateY: [300, 0],
                opacity: [0, 1],
                easing: 'easeOutSine',
                duration: 500,
                delay: 2000
            });

            // about
            anime({
                targets: '#geba-corp-bottom-text',
                translateY: [400, 0],
                opacity: [0, 0.5, 1],
                easing: 'easeOutSine',
                duration: 600,
                delay: 2500
            });

            // balloon
           document.getElementById('geba-corp-balloon').classList.add('anim');
        }
    }

})( jQuery );
