//console.log("in roth")
var IndvidualIncome = [
    112000, 
    113000, 
    114000, 
    115000, 
    116000, 
    117000, 
    118000, 
    119000, 
    120000, 
    121000, 
    122000, 
    123000, 
    124000, 
    125000, 
    126000, 
    127000
]
var MarriedIncome = [
    178000, 
    179000, 
    180000, 
    181000, 
    182000, 
    183000, 
    184000, 
    185000, 
    186000, 
    187000, 
    188000
]
var MarriedSeperatlyIncome = [
    0,
    1000,
    2000,
    3000,
    4000,
    5000,
    6000,
    7000,
    8000,
    9000,
    10000
];

var applyRothContributions = function(quickForm){
    var RothContributions = quickForm;
    //hide submit
    RothContributions.querySelector("button[type='submit']").style.display = "none";

    //when inputs change
    var performChange = function(event){
        //console.log("performing change");
        var selectedAnswers = [];
        var outputElement = document.querySelector(".elementor-element-23dc178 p");
        var form = RothContributions
        var outputValue = 6500;
        //grab all selected answers
        for ( var i = 0; i < form.elements.length; i++ ) {
            var e = form.elements[i];
            if(e.name && e.type != "hidden" && e.name !="frm_verify" && e.value != ""){
                // console.log({
                //     'value': e.value,
                //     'type': e.type,
                //     'element': e
                // });
                if(
                    (e.selected || e.checked || e.classList.contains("hasDatepicker")) ||
                    (e.type != "select" && e.type != "radio")
                ){
                    // the "e" left over are all the answers selected in order

                    //go thru the calculation tree and compare values
                    //console.log(e.value);
                    selectedAnswers.push({
                        e, "value": e.value
                    });
                }
            }
        }
        var performCalculation = function(){
            var status = selectedAnswers[0].value;
            var age = selectedAnswers[1].value;
            var income = parseFloat(selectedAnswers[2].value);
            
            if(status === "Individual"){
                //console.log(1);
                if(age === "Under 50"){
                    //console.log(2);
                    outputValue = 5500;
                }else if(age === "50 and Over"){
                    //console.log(3);
                    if(income <= IndvidualIncome[0] && income >= IndvidualIncome[1]){
                        console.log(4);
                        outputValue = 6500;
                    }else if(income >= IndvidualIncome[IndvidualIncome.length -1]){
                        //console.log(5);
                        outputValue = 0;
                    }else{
                        //console.log(6);
                        var lastVal = IndvidualIncome[IndvidualIncome.length -1]
                        for (let index = 0; index < IndvidualIncome.length; index++) {
                            if(income >= IndvidualIncome[index]){
                                if(IndvidualIncome[index + 1] && income < IndvidualIncome[index + 1]){
                                    outputValue = 
                                        ((
                                            lastVal - 
                                            IndvidualIncome[index]
                                        )/ 15000) * 6500
                                }
                            }
                            
                        }
                    }
                }
            }else if(status === "Married filing jointly"){
                //console.log(1);
                var rothBase = 6500;
                if(age === "Under 50"){
                    rothBase = 5500;
                    //console.log(2);
                }else if(age === "50 and Over"){
                    rothBase = 6500;
                    //console.log(3);
                }
                if(income <= MarriedIncome[0] && income >= MarriedIncome[1]){
                    //console.log(4);
                    //outputValue = 6500;
                }else if(income >= MarriedIncome[MarriedIncome.length -1]){
                    //console.log(5);
                    outputValue = 0;
                }else{
                    //console.log(6);
                    var lastVal = MarriedIncome[MarriedIncome.length -1]
                    for (let index = 0; index < MarriedIncome.length; index++) {
                        if(income >= MarriedIncome[index]){
                            if(MarriedIncome[index + 1] && income < MarriedIncome[index + 1]){
                                outputValue = 
                                    ((
                                        lastVal - 
                                        MarriedIncome[index]
                                    )/ 10000) * rothBase
                            }
                        }
                        
                    }
                }
            }else if(status === "Married filing separately"){
                //console.log(1);
                var rothBase = 6500;
                if(age === "Under 50"){
                    rothBase = 5500;
                    //console.log(2);
                }else if(age === "50 and Over"){
                    rothBase = 6500;
                    //console.log(3);
                }
                if(income <= MarriedSeperatlyIncome[0] && income >= MarriedSeperatlyIncome[1]){
                    //console.log(4);
                    outputValue = 6500;
                }else if(income >= MarriedSeperatlyIncome[MarriedSeperatlyIncome.length -1]){
                    //console.log(5);
                    outputValue = 0;
                }else{
                    //console.log(6);
                    var lastVal = MarriedSeperatlyIncome[MarriedSeperatlyIncome.length -1]
                    for (let index = 0; index < MarriedSeperatlyIncome.length; index++) {
                        if(income >= MarriedSeperatlyIncome[index]){
                            if(MarriedSeperatlyIncome[index + 1] && income < MarriedSeperatlyIncome[index + 1]){
                                outputValue = 
                                    ((
                                        lastVal - 
                                        MarriedSeperatlyIncome[index]
                                    )/ 10000) * rothBase
                            }
                        }
                        
                    }
                }
            }
            //console.log(selectedAnswers, outputValue);
            outputElement.innerHTML = `
                You may contribute up to $${outputValue.toFixed(2)} in a Roth IRA this year. GEBA offers both
                traditional and Roth IRAs for your benefit. Our licensed Consultants can assist you 
                with your investment needs. Call GEBA at (800) 826-1126 to learn more.
            `;
        }
        if(selectedAnswers[2]){
            performCalculation();
        }
    }

    //grab all inputs apply change
    RothContributions.addEventListener("change", performChange);
}

//grab container
var RothContributions = document.querySelector("#frm_form_4_container form");

if(RothContributions){
    applyRothContributions(RothContributions);
}

