//console.log("in gebla and fegli");
(function(){
    var applyGebaVsFegli = function(quickForm){
        var gebaChart = [
            {benefit: '50', premium: '1', ageLow: '0', ageHigh: '30'},
            {benefit: '100', premium: '2', ageLow: '0', ageHigh: '30'},
            {benefit: '150', premium: '3', ageLow: '0', ageHigh: '30'},
            {benefit: '200', premium: '4', ageLow: '0', ageHigh: '30'},
            {benefit: '250', premium: '5', ageLow: '0', ageHigh: '30'},
            {benefit: '300', premium: '6', ageLow: '0', ageHigh: '30'},
            {benefit: '350', premium: '7', ageLow: '0', ageHigh: '30'},
            {benefit: '400', premium: '8', ageLow: '0', ageHigh: '30'},
            {benefit: '450', premium: '9', ageLow: '0', ageHigh: '30'},
            {benefit: '500', premium: '10', ageLow: '0', ageHigh: '30'},
            {benefit: '550', premium: '11', ageLow: '0', ageHigh: '30'},
            {benefit: '600', premium: '12', ageLow: '0', ageHigh: '30'},
            {benefit: '50', premium: '2', ageLow: '31', ageHigh: '40'},
            {benefit: '100', premium: '3', ageLow: '31', ageHigh: '40'},
            {benefit: '150', premium: '5', ageLow: '31', ageHigh: '40'},
            {benefit: '200', premium: '6', ageLow: '31', ageHigh: '40'},
            {benefit: '250', premium: '8', ageLow: '31', ageHigh: '40'},
            {benefit: '300', premium: '9', ageLow: '31', ageHigh: '40'},
            {benefit: '350', premium: '11', ageLow: '31', ageHigh: '40'},
            {benefit: '400', premium: '12', ageLow: '31', ageHigh: '40'},
            {benefit: '450', premium: '14', ageLow: '31', ageHigh: '40'},
            {benefit: '500', premium: '15', ageLow: '31', ageHigh: '40'},
            {benefit: '550', premium: '17', ageLow: '31', ageHigh: '40'},
            {benefit: '600', premium: '18', ageLow: '31', ageHigh: '40'},
            {benefit: '50', premium: '3', ageLow: '41', ageHigh: '50'},
            {benefit: '70', premium: '4', ageLow: '41', ageHigh: '50'},
            {benefit: '80', premium: '5', ageLow: '41', ageHigh: '50'},
            {benefit: '100', premium: '6', ageLow: '41', ageHigh: '50'},
            {benefit: '150', premium: '9', ageLow: '41', ageHigh: '50'},
            {benefit: '200', premium: '12', ageLow: '41', ageHigh: '50'},
            {benefit: '250', premium: '15', ageLow: '41', ageHigh: '50'},
            {benefit: '300', premium: '18', ageLow: '41', ageHigh: '50'},
            {benefit: '350', premium: '21', ageLow: '41', ageHigh: '50'},
            {benefit: '400', premium: '24', ageLow: '41', ageHigh: '50'},
            {benefit: '450', premium: '27', ageLow: '41', ageHigh: '50'},
            {benefit: '500', premium: '30', ageLow: '41', ageHigh: '50'},
            {benefit: '550', premium: '33', ageLow: '41', ageHigh: '50'},
            {benefit: '600', premium: '36', ageLow: '41', ageHigh: '50'},
            {benefit: '50', premium: '6', ageLow: '51', ageHigh: '55'},
            {benefit: '60', premium: '7', ageLow: '51', ageHigh: '55'},
            {benefit: '70', premium: '8', ageLow: '51', ageHigh: '55'},
            {benefit: '80', premium: '10', ageLow: '51', ageHigh: '55'},
            {benefit: '90', premium: '11', ageLow: '51', ageHigh: '55'},
            {benefit: '100', premium: '12', ageLow: '51', ageHigh: '55'},
            {benefit: '150', premium: '18', ageLow: '51', ageHigh: '55'},
            {benefit: '200', premium: '24', ageLow: '51', ageHigh: '55'},
            {benefit: '250', premium: '30', ageLow: '51', ageHigh: '55'},
            {benefit: '300', premium: '36', ageLow: '51', ageHigh: '55'},
            {benefit: '350', premium: '42', ageLow: '51', ageHigh: '55'},
            {benefit: '400', premium: '48', ageLow: '51', ageHigh: '55'},
            {benefit: '450', premium: '54', ageLow: '51', ageHigh: '55'},
            {benefit: '500', premium: '60', ageLow: '51', ageHigh: '55'},
            {benefit: '550', premium: '66', ageLow: '51', ageHigh: '55'},
            {benefit: '600', premium: '72', ageLow: '51', ageHigh: '55'},
            {benefit: '50', premium: '11', ageLow: '56', ageHigh: '60'},
            {benefit: '60', premium: '13', ageLow: '56', ageHigh: '60'},
            {benefit: '70', premium: '15', ageLow: '56', ageHigh: '60'},
            {benefit: '80', premium: '17', ageLow: '56', ageHigh: '60'},
            {benefit: '90', premium: '19', ageLow: '56', ageHigh: '60'},
            {benefit: '100', premium: '21', ageLow: '56', ageHigh: '60'},
            {benefit: '150', premium: '32', ageLow: '56', ageHigh: '60'},
            {benefit: '200', premium: '42', ageLow: '56', ageHigh: '60'},
            {benefit: '250', premium: '53', ageLow: '56', ageHigh: '60'},
            {benefit: '300', premium: '63', ageLow: '56', ageHigh: '60'},
            {benefit: '350', premium: '74', ageLow: '56', ageHigh: '60'},
            {benefit: '400', premium: '84', ageLow: '56', ageHigh: '60'},
            {benefit: '450', premium: '95', ageLow: '56', ageHigh: '60'},
            {benefit: '500', premium: '105', ageLow: '56', ageHigh: '60'},
            {benefit: '550', premium: '116', ageLow: '56', ageHigh: '60'},
            {benefit: '600', premium: '126', ageLow: '56', ageHigh: '60'},
            {benefit: '50', premium: '15', ageLow: '61', ageHigh: '64'},
            {benefit: '60', premium: '18', ageLow: '61', ageHigh: '64'},
            {benefit: '70', premium: '21', ageLow: '61', ageHigh: '64'},
            {benefit: '80', premium: '24', ageLow: '61', ageHigh: '64'},
            {benefit: '90', premium: '27', ageLow: '61', ageHigh: '64'},
            {benefit: '100', premium: '30', ageLow: '61', ageHigh: '64'},
            {benefit: '150', premium: '45', ageLow: '61', ageHigh: '64'},
            {benefit: '200', premium: '60', ageLow: '61', ageHigh: '64'},
            {benefit: '250', premium: '75', ageLow: '61', ageHigh: '64'},
            {benefit: '300', premium: '90', ageLow: '61', ageHigh: '64'},
            {benefit: '350', premium: '105', ageLow: '61', ageHigh: '64'},
            {benefit: '400', premium: '120', ageLow: '61', ageHigh: '64'},
            {benefit: '450', premium: '134', ageLow: '61', ageHigh: '64'},
            {benefit: '500', premium: '149', ageLow: '61', ageHigh: '64'},
            {benefit: '550', premium: '164', ageLow: '61', ageHigh: '64'},
            {benefit: '600', premium: '179', ageLow: '61', ageHigh: '64'},
            {benefit: '25', premium: '15', ageLow: '65', ageHigh: '69'},
            {benefit: '30', premium: '18', ageLow: '65', ageHigh: '69'},
            {benefit: '35', premium: '21', ageLow: '65', ageHigh: '69'},
            {benefit: '40', premium: '24', ageLow: '65', ageHigh: '69'},
            {benefit: '45', premium: '27', ageLow: '65', ageHigh: '69'},
            {benefit: '50', premium: '30', ageLow: '65', ageHigh: '69'},
            {benefit: '75', premium: '45', ageLow: '65', ageHigh: '69'},
            {benefit: '100', premium: '60', ageLow: '65', ageHigh: '69'},
            {benefit: '125', premium: '75', ageLow: '65', ageHigh: '69'},
            {benefit: '150', premium: '90', ageLow: '65', ageHigh: '69'},
            {benefit: '175', premium: '105', ageLow: '65', ageHigh: '69'},
            {benefit: '200', premium: '120', ageLow: '65', ageHigh: '69'},
            {benefit: '225', premium: '134', ageLow: '65', ageHigh: '69'},
            {benefit: '250', premium: '149', ageLow: '65', ageHigh: '69'},
            {benefit: '275', premium: '164', ageLow: '65', ageHigh: '69'},
            {benefit: '300', premium: '179', ageLow: '65', ageHigh: '69'},
            {benefit: '20', premium: '15', ageLow: '70', ageHigh: '74'},
            {benefit: '24', premium: '18', ageLow: '70', ageHigh: '74'},
            {benefit: '28', premium: '21', ageLow: '70', ageHigh: '74'},
            {benefit: '32', premium: '24', ageLow: '70', ageHigh: '74'},
            {benefit: '36', premium: '27', ageLow: '70', ageHigh: '74'},
            {benefit: '40', premium: '30', ageLow: '70', ageHigh: '74'},
            {benefit: '60', premium: '45', ageLow: '70', ageHigh: '74'},
            {benefit: '80', premium: '60', ageLow: '70', ageHigh: '74'},
            {benefit: '100', premium: '120', ageLow: '70', ageHigh: '74'},
            {benefit: '120', premium: '75', ageLow: '70', ageHigh: '74'},
            {benefit: '140', premium: '134', ageLow: '70', ageHigh: '74'},
            {benefit: '160', premium: '90', ageLow: '70', ageHigh: '74'},
            {benefit: '180', premium: '149', ageLow: '70', ageHigh: '74'},
            {benefit: '200', premium: '105', ageLow: '70', ageHigh: '74'},
            {benefit: '220', premium: '164', ageLow: '70', ageHigh: '74'},
            {benefit: '240', premium: '179', ageLow: '70', ageHigh: '74'},
            {benefit: '15', premium: '15', ageLow: '75', ageHigh: '79'},
            {benefit: '18', premium: '18', ageLow: '75', ageHigh: '79'},
            {benefit: '21', premium: '21', ageLow: '75', ageHigh: '79'},
            {benefit: '24', premium: '24', ageLow: '75', ageHigh: '79'},
            {benefit: '27', premium: '27', ageLow: '75', ageHigh: '79'},
            {benefit: '30', premium: '30', ageLow: '75', ageHigh: '79'},
            {benefit: '45', premium: '45', ageLow: '75', ageHigh: '79'},
            {benefit: '60', premium: '60', ageLow: '75', ageHigh: '79'},
            {benefit: '75', premium: '120', ageLow: '75', ageHigh: '79'},
            {benefit: '90', premium: '75', ageLow: '75', ageHigh: '79'},
            {benefit: '105', premium: '134', ageLow: '75', ageHigh: '79'},
            {benefit: '120', premium: '90', ageLow: '75', ageHigh: '79'},
            {benefit: '135', premium: '149', ageLow: '75', ageHigh: '79'},
            {benefit: '150', premium: '105', ageLow: '75', ageHigh: '79'},
            {benefit: '165', premium: '164', ageLow: '75', ageHigh: '79'},
            {benefit: '180', premium: '179', ageLow: '75', ageHigh: '79'},
            {benefit: '10', premium: '15', ageLow: '80', ageHigh: '1000'},
            {benefit: '12', premium: '18', ageLow: '80', ageHigh: '1000'},
            {benefit: '14', premium: '21', ageLow: '80', ageHigh: '1000'},
            {benefit: '16', premium: '24', ageLow: '80', ageHigh: '1000'},
            {benefit: '18', premium: '27', ageLow: '80', ageHigh: '1000'},
            {benefit: '20', premium: '30', ageLow: '80', ageHigh: '1000'},
        ];
        var fegliChart = [
            {age: '20', factor: '2', optionACost: '0.3', optionBCost: '0.02', optionCCost: '0.22'},
            {age: '25', factor: '2', optionACost: '0.3', optionBCost: '0.02', optionCCost: '0.22'},
            {age: '30', factor: '2', optionACost: '0.3', optionBCost: '0.02', optionCCost: '0.22'},
            {age: '35', factor: '2', optionACost: '0.4', optionBCost: '0.03', optionCCost: '0.29'},
            {age: '36', factor: '1.9', optionACost: '0.4', optionBCost: '0.03', optionCCost: '0.29'},
            {age: '37', factor: '1.8', optionACost: '0.4', optionBCost: '0.03', optionCCost: '0.29'},
            {age: '38', factor: '1.7', optionACost: '0.4', optionBCost: '0.03', optionCCost: '0.29'},
            {age: '39', factor: '1.6', optionACost: '0.4', optionBCost: '0.03', optionCCost: '0.29'},
            {age: '40', factor: '1.5', optionACost: '0.6', optionBCost: '0.05', optionCCost: '0.42'},
            {age: '41', factor: '1.4', optionACost: '0.6', optionBCost: '0.05', optionCCost: '0.42'},
            {age: '42', factor: '1.3', optionACost: '0.6', optionBCost: '0.05', optionCCost: '0.42'},
            {age: '43', factor: '1.2', optionACost: '0.6', optionBCost: '0.05', optionCCost: '0.42'},
            {age: '44', factor: '1.1', optionACost: '0.6', optionBCost: '0.05', optionCCost: '0.42'},
            {age: '45', factor: '1', optionACost: '0.9', optionBCost: '0.08', optionCCost: '0.63'},
            {age: '50', factor: '1', optionACost: '1.4', optionBCost: '0.13', optionCCost: '0.94'},
            {age: '55', factor: '1', optionACost: '2.7', optionBCost: '0.23', optionCCost: '1.52'},
            {age: '60', factor: '1', optionACost: '6', optionBCost: '0.52', optionCCost: '2.7'},
            {age: '65', factor: '1', optionACost: '', optionBCost: '0.62', optionCCost: '3.14'},
            {age: '70', factor: '1', optionACost: '', optionBCost: '1.14', optionCCost: '3.6'},
            {age: '75', factor: '1', optionACost: '', optionBCost: '1.8', optionCCost: '4.8'},
            {age: '80', factor: '1', optionACost: '', optionBCost: '2.4', optionCCost: '6.6'}
        ];
        var gebaOutput = {
            totalMemberCoverage: 0,
            MemberCoverageCost: 0,
            totalSpouseCoverage: 0,
            spouseCoverageCost: 0,
            totalDependentCoverage: 0,
            dependentCoverageCost: 0,
            totalBiweeklyCost: 0,
            annualCost: 0
        };
        var fegliOutput = {
            totalMemberCoverage: 0,
            MemberCoverageCost: 0,
            totalSpouseCoverage: 0,
            spouseCoverageCost: 0,
            totalDependentCoverage: 0,
            dependentCoverageCost: 0,
            totalBiweeklyCost: 0,
            annualCost: 0
        };

        var selectedAnswers = {
            firsName: "",
            lastName: "",
            email: "",
            age: 0,
            annualBasicPay: 0,
            optionA: 0,
            optionB: 0,
            spouseInsurance: 0,
            spouseAge: 0,
            childDependentCoverage: 0,
            premium: 0
        }
        
        var gebaVsFegliForm = quickForm;
        //hide submit
        gebaVsFegliForm.querySelector("button[type='submit']").style.display = "none";
        //grab all inputs
        gebaVsFegliForm.addEventListener("change", function(event){
            //var outputElement = quickDental.querySelector(".frm_total_big input");
            var form = gebaVsFegliForm
            var outputValue = "";
            var selectedElements = [];
            //grab all selected answers
            for ( var i = 0; i < form.elements.length; i++ ) {
                var e = form.elements[i];
                if(e.name && e.type != "hidden" && e.name !="frm_verify" && e.value != ""){
                    if(
                        (e.selected || e.checked) ||
                        (e.type != "select" && e.type != "radio")
                        ){
                            // the "e" left over are all the answers in order
                            selectedElements.push(e);
                            var label = e.labels[0].innerText;
                            if(label.startsWith("First Name")){
                                selectedAnswers.firsName = e.value.trim()  
                            }
                            if(label.startsWith("Last Name")){
                                selectedAnswers.lastName = e.value.trim()  
                            }
                            if(label.startsWith("Email")){
                                selectedAnswers.email = e.value.trim()  
                            }
                            if(label.startsWith("Age")){
                                selectedAnswers.age = e.value.trim()  
                            }
                            if(label.startsWith("Annual Basic")){
                                selectedAnswers.annualBasicPay = e.value.trim()
                            }
                            if(label.startsWith("Spouse Age")){
                                selectedAnswers.spouseAge = e.value.trim()
                            }
                            if(e.type === "radio"){
                                var radioLabelId = "field_" + e.id.substring(6).split("-")[0] + "_label";
                                var radioLabel = document.getElementById(radioLabelId).innerText;
                                if(radioLabel.startsWith("Option A")){
                                    selectedAnswers.optionA = e.value.trim()
                                }
                                if(radioLabel.startsWith("Option B")){
                                    selectedAnswers.optionB = e.value.trim()
                                }
                                if(radioLabel.startsWith("Spouse Coverage")){
                                    selectedAnswers.spouseInsurance = e.value.trim()
                                }
                                if(radioLabel.startsWith("Child Dependent")){
                                    selectedAnswers.childDependentCoverage = e.value.trim()
                                }
                            }
                        }
                    }
                }
                var performCalculation = function(){
                    //console.log(selectedElements);
                    selectedAnswers.premium = 0;
                    //Geba Calculations
                        //calculate premium and benefit
                        if(selectedAnswers.optionA == "Yes"){
                            selectedAnswers.premium = parseFloat(selectedAnswers.premium) + 1;
                        }
                        selectedAnswers.premium = parseFloat(selectedAnswers.premium) + parseFloat(selectedAnswers.optionB);
                        if(selectedAnswers.spousInsurance == "Yes"){
                            selectedAnswers.premium = parseFloat(selectedAnswers.premium) + 1;
                        }
                        if(selectedAnswers.childDependentCoverage == "Yes"){
                            selectedAnswers.premium = parseFloat(selectedAnswers.premium) + 1;
                        }
                        selectedAnswers.benefit = (
                            parseFloat(selectedAnswers.premium) *
                            parseFloat(selectedAnswers.annualBasicPay)
                         ) / 1000;
                        console.log(selectedAnswers);
                        //Find correct Geba Index
                        var getGebaRowFromAgeBenefit =  function(age, benefit, type){
                            var gebaChartIndex = null;
                            for (let index = 0; index < gebaChart.length; index++) {
                                if(gebaChart[index].ageLow <= age ){
                                    console.log(1 + " " + type);
                                    if(gebaChart[index].ageHigh >= age){
                                        console.log(2 + " " + type);
                                        console.log(gebaChart[index]);
                                        if(gebaChart[index].benefit == benefit ){
                                            console.log(3 + " " + type);
                                            gebaChartIndex = index;
                                            break; 
                                        }else{
                                            if(gebaChart[index+1]){
                                                console.log(gebaChart[index]);
                                                console.log(gebaChart[index + 1]);
                                                console.log((gebaChart[index].benefit >= gebaChart[index + 1].benefit));
                                                if(parseFloat(gebaChart[index].benefit) >= parseFloat(gebaChart[index + 1].benefit)){
                                                    console.log(4 + " " + type);
                                                    gebaChartIndex = index;
                                                    break;
                                                }
                                            }
                                            
                                        }
                                    }
                                }
                            }
                            return gebaChartIndex
                        }
                        //store calculated values
                        var currentGebaRowIndex = getGebaRowFromAgeBenefit(selectedAnswers.age, selectedAnswers.benefit);
                        if(currentGebaRowIndex){
                            var currentGebaRow = gebaChart[currentGebaRowIndex];
                            //console.log(currentGebaRow);
                            gebaOutput.totalMemberCoverage = parseFloat(currentGebaRow.benefit * 1000);
                            gebaOutput.MemberCoverageCost = parseFloat(currentGebaRow.premium);
                            gebaOutput.totalSpouseCoverage = 
                                selectedAnswers.spouseInsurance === "Yes" ?
                                    50000 : 0;
                            var currentSpouseRowIndex = getGebaRowFromAgeBenefit(selectedAnswers.spouseAge, (gebaOutput.totalSpouseCoverage / 1000), "spouse");
                            console.log(currentSpouseRowIndex);
                            if(currentSpouseRowIndex){
                                console.log('found spouse row'+ currentSpouseRowIndex);
                                gebaOutput.spouseCoverageCost = parseFloat(gebaChart[currentSpouseRowIndex].premium);
                            }
                            gebaOutput.totalDependentCoverage = 
                                selectedAnswers.childDependentCoverage === "Yes" ?
                                    20000 : 0;
                            gebaOutput.dependentCoverageCost = 
                                selectedAnswers.childDependentCoverage === "Yes" ?
                                    1 : 0;
                            gebaOutput.totalBiweeklyCost = 
                                gebaOutput.MemberCoverageCost +
                                gebaOutput.spouseCoverageCost +
                                gebaOutput.dependentCoverageCost;
                            gebaOutput.annualCost = gebaOutput.totalBiweeklyCost * 26;
                        }
                        
                        //console.log(gebaChart)
                        console.log(gebaOutput);
                    // Fegli Calculations
                        //Find correct figli Index
                        var getFegliRowFromAge =  function(age, type){
                            var fegliChartIndex = null;
                            for (let index = 0; index < fegliChart.length; index++) {
                                if(fegliChart[index].age == age ){
                                    console.log(3 + " " + type);
                                    gebaChartIndex = index;
                                    break; 
                                }else{
                                    if(fegliChart[index+1]){
                                        console.log(fegliChart[index]);
                                        console.log(fegliChart[index + 1]);
                                        console.log((fegliChart[index].age >= fegliChart[index + 1].age));
                                        if(parseFloat(fegliChart[index].age) >= parseFloat(age)){
                                            console.log(4 + " " + type);
                                            fegliChartIndex = index;
                                            break;
                                        }
                                    }
                                    
                                }
                            }
                            return fegliChartIndex
                        }

                        var fegliRowIndex = getFegliRowFromAge(selectedAnswers.age)
                        if(fegliRowIndex){
                            currentFegliRow = fegliChart[fegliRowIndex];
                            //(B3+2000)+(Y5*(B3+2000))-(B3+2000)+(10000*1)+(B5*B3)
                            fegliOutput.totalMemberCoverage = 
                                (parseFloat(selectedAnswers.annualBasicPay) + 2000) +
                                (parseFloat(currentFegliRow.factor) * (parseFloat(selectedAnswers.annualBasicPay) + 2000)) -
                                (parseFloat(selectedAnswers.annualBasicPay) + 2000) +
                                parseFloat(10000*1) + (parseFloat(selectedAnswers.optionB) * parseFloat(selectedAnswers.annualBasicPay));
                            //=(((B3+2000)/1000)*0.15)+(Z5)+(AA5*((B5*B3)/1000))    
                            fegliOutput.MemberCoverageCost = 
                                (((parseFloat(selectedAnswers.annualBasicPay) + 2000) / 1000) * 0.15) +
                                parseFloat(currentFegliRow.optionACost) +
                                (parseFloat(currentFegliRow.optionBCost) * ((parseFloat(selectedAnswers.optionB) * parseFloat(selectedAnswers.annualBasicPay) / 1000)));
                            fegliOutput.totalSpouseCoverage = 
                                selectedAnswers.spouseInsurance === "Yes" ?
                                    25000 : 0;
                            fegliOutput.totalDependentCoverage = 
                                selectedAnswers.childDependentCoverage === "Yes" ?
                                    12500 : 0;
                            fegliOutput.dependentCoverageCost = 
                                selectedAnswers.childDependentCoverage === "Yes" ?
                                    currentFegliRow.optionCCost * 5 : 0;
                            fegliOutput.totalBiweeklyCost = 
                                parseFloat(fegliOutput.MemberCoverageCost) +
                                parseFloat(fegliOutput.dependentCoverageCost);
                            fegliOutput.annualCost = parseFloat(fegliOutput.totalBiweeklyCost) * 26;

                        }



                    //Output Calculations
                        var gebaTbody = document.getElementById("gebaTbody");
                        if(!document.getElementById("gebaTbody")){
                            var newEl = document.createElement('div');
                            newEl.innerHTML = `
                                <h2>Output</h2>
                                <table>
                                    <thead>
                                        <tr>
                                            <td></td>
                                            <td>GEBA</td>
                                            <td>FEGLI</td>
                                        </tr>
                                    <tbody id="gebaTbody"></tbody>
                                </table>`;
                            document.getElementsByClassName('elementor-element-65a0e77')[0].appendChild(newEl);
                            //console.log(gebaTbody);
                        }
                        
                        var gebaOutputRows = ""
                        for (const item in gebaOutput) {
                            var tr = "<tr>";

                            /* Verification to add the last decimal 0 */
                            if (gebaOutput[item].toString().substring(gebaOutput[item].toString().indexOf('.'), gebaOutput[item].toString().length) < 2){ 
                                gebaOutput[item] += "0";
                            }

                            /* Must not forget the $ sign */
                            //Convert camel case object name to readable
                            var addSpaces = item.replace( /([A-Z])/g, " $1" );
                            var finalResult = addSpaces.charAt(0).toUpperCase() + addSpaces.slice(1);
                            tr += "<td>" + finalResult + "</td>" + "<td>$" + gebaOutput[item].toString() + "</td>" + "<td>$" + fegliOutput[item].toString() + "</td></tr>";

                            /* We add the table row to the table body */
                            console.log(tr);
                            gebaOutputRows += tr;
                            
                        }
                        //Output geba calculations
                        if(gebaTbody){
                            gebaTbody.innerHTML = gebaOutputRows;
                        }
                        
                    
                        

                }
                performCalculation();
                //console.log(outputValue);
                //console.log(outputElement);
                    //outputElement.value = outputValue === "N/A" ? outputValue : "$" + outputValue;
                //console.log(selectedAnswers);
                //var queryString = kvpairs.join("&");
                //console.log(selectedAnswers);
                //find output element
                
                
            });
            var inputs = gebaVsFegliForm.querySelectorAll(`input:not([disabled]):not([type='submit']),
            select:not([disabled]):not([type='submit'])`)
            //console.log(inputs);
        }
        //grab container
        var gebaVsFegli = document.querySelector("#frm_form_16_container form");
        if(gebaVsFegli){
            applyGebaVsFegli(gebaVsFegli);
        }
        
        
    })();