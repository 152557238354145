(function() {
    var wealth = document.getElementById( 'geba-wealth-group' );

    if ( wealth !== null ) {
        run_anim();

        n2ss.ready(2, function( slider, sliderElement ) {
            // re-run anim on slide change
            sliderElement.on( 'mainAnimationComplete',
                function( e, animation, previousSlideIndex, currentSlideIndex ) {
                    run_anim();
                });
        });
    }

    function run_anim() {
        anime({
            targets: '#geba-wealth-reaching-higher',
            opacity: [0, 1],
            easing: 'linear',
            duration: 1500,
            loop: false,
            delay: 6000
        });

        anime({
            targets: '#geba-wealth-bottom-text',
            opacity: [0, 1],
            easing: 'linear',
            duration: 400,
            loop: false,
            delay: 7000
        });

        anime({
            targets: '#geba-wealth-rocket',
            translateY: [ 800, 0 ],
            easing: 'easeInOutExpo',
            duration: 10000,
            loop: false
        });

        anime({
            targets: '#geba-clip',
            translateY: [ 800, -90 ],
            easing: 'easeInOutExpo',
            duration: 10000,
            loop: false
        });
    }

})();
