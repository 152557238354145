var applyQuickTerm = function(quickForm){
    calculationTree = {
        "Bi-weekly":{
            ">31":{
                "$50,000":"1",
                "$60,000":"N/A",
                "$70,000":"N/A",
                "$80,000":"N/A",
                "$90,000":"N/A",
                "$100,000":"2",
                "$150,000":"3",
                "$200,000":"4",
                "$250,000":"5",
                "$300,000":"6",
                "$350,000":"7",
                "$400,000":"8",
                "$450,000":"9",
                "$500,000":"10",
                "$550,000":"11",
                "$600,000":"12"
            },
            "31-40":{
                "$50,000":"2",
                "$60,000":"N/A",
                "$70,000":"N/A",
                "$80,000":"N/A",
                "$90,000":"N/A",
                "$100,000":"3",
                "$150,000":"5",
                "$200,000":"6",
                "$250,000":"8",
                "$300,000":"9",
                "$350,000":"11",
                "$400,000":"12",
                "$450,000":"14",
                "$500,000":"15",
                "$550,000":"17",
                "$600,000":"18"
            },
            "41-50":{
                "$50,000":"3",
                "$60,000":"N/A",
                "$70,000":"4",
                "$80,000":"5",
                "$90,000":"N/A",
                "$100,000":"6",
                "$150,000":"9",
                "$200,000":"12",
                "$250,000":"15",
                "$300,000":"18",
                "$350,000":"21",
                "$400,000":"24",
                "$450,000":"27",
                "$500,000":"30",
                "$550,000":"33",
                "$600,000":"36"
            },
            "51-55":{
                "$50,000":"6",
                "$60,000":"7",
                "$70,000":"8",
                "$80,000":"9",
                "$90,000":"11",
                "$100,000":"12",
                "$150,000":"18",
                "$200,000":"24",
                "$250,000":"30",
                "$300,000":"36",
                "$350,000":"42",
                "$400,000":"48",
                "$450,000":"54",
                "$500,000":"60",
                "$550,000":"66",
                "$600,000":"72"
            },
            "56-60":{
                "$50,000":"11",
                "$60,000":"13",
                "$70,000":"15",
                "$80,000":"17",
                "$90,000":"19",
                "$100,000":"21",
                "$150,000":"32",
                "$200,000":"42",
                "$250,000":"53",
                "$300,000":"63",
                "$350,000":"74",
                "$400,000":"84",
                "$450,000":"95",
                "$500,000":"105",
                "$550,000":"116",
                "$600,000":"126"
            },
            "61-64":{
                "$50,000":"15",
                "$60,000":"18",
                "$70,000":"21",
                "$80,000":"24",
                "$90,000":"27",
                "$100,000":"30",
                "$150,000":"45",
                "$200,000":"60",
                "$250,000":"75",
                "$300,000":"90",
                "$350,000":"105",
                "$400,000":"120",
                "$450,000":"134",
                "$500,000":"149",
                "$550,000":"164",
                "$600,000":"179"
            },
            "61<": {
                "$50,000":"N/A",
                "$60,000":"N/A",
                "$70,000":"N/A",
                "$80,000":"N/A",
                "$90,000":"N/A",
                "$100,000":"N/A",
                "$150,000":"N/A",
                "$200,000":"N/A",
                "$250,000":"N/A",
                "$300,000":"N/A",
                "$350,000":"N/A",
                "$400,000":"N/A",
                "$450,000":"N/A",
                "$500,000":"N/A",
                "$550,000":"N/A",
                "$600,000":"N/A"
            }
        },
        "Monthly":{
            ">31":{
                "$50,000":"2.17",
                "$60,000":"N/A",
                "$70,000":"N/A",
                "$80,000":"N/A",
                "$90,000":"N/A",
                "$100,000":"4.33",
                "$150,000":"6.50",
                "$200,000":"8.67",
                "$250,000":"10.83",
                "$300,000":"13.00",
                "$350,000":"15.17",
                "$400,000":"17.33",
                "$450,000":"19.50",
                "$500,000":"21.67",
                "$550,000":"23.83",
                "$600,000":"26"
            },
            "31-40":{
                "$50,000":"4.33",
                "$60,000":"N/A",
                "$70,000":"N/A",
                "$80,000":"N/A",
                "$90,000":"N/A",
                "$100,000":"6.5",
                "$150,000":"10.83",
                "$200,000":"13",
                "$250,000":"17.33",
                "$300,000":"19.50",
                "$350,000":"23.83",
                "$400,000":"26",
                "$450,000":"30.33",
                "$500,000":"32.50",
                "$550,000":"36.83",
                "$600,000":"39"
            },
            "41-50":{
                "$50,000":"6.50",
                "$60,000":"N/A",
                "$70,000":"8.67",
                "$80,000":"10.83",
                "$90,000":"N/A",
                "$100,000":"13",
                "$150,000":"19.5",
                "$200,000":"26",
                "$250,000":"32.5",
                "$300,000":"39",
                "$350,000":"45.5",
                "$400,000":"52",
                "$450,000":"58.5",
                "$500,000":"65",
                "$550,000":"71.5",
                "$600,000":"78"
            },
            "51-55":{
                "$50,000":"13",
                "$60,000":"15.17",
                "$70,000":"17.33",
                "$80,000":"21.67",
                "$90,000":"23.83",
                "$100,000":"26",
                "$150,000":"39",
                "$200,000":"52",
                "$250,000":"65",
                "$300,000":"78",
                "$350,000":"91",
                "$400,000":"104",
                "$450,000":"117",
                "$500,000":"130",
                "$550,000":"143",
                "$600,000":"156"
            },
            "56-60":{
                "$50,000":"23.83",
                "$60,000":"28.17",
                "$70,000":"32.50",
                "$80,000":"36.83",
                "$90,000":"41.17",
                "$100,000":"45.50",
                "$150,000":"69.33",
                "$200,000":"91",
                "$250,000":"114.83",
                "$300,000":"136.50",
                "$350,000":"160.33",
                "$400,000":"182",
                "$450,000":"205.83",
                "$500,000":"227.5",
                "$550,000":"251.33",
                "$600,000":"273"
            },
            "61-64":{
                "$50,000":"32.5",
                "$60,000":"39",
                "$70,000":"45.5",
                "$80,000":"52",
                "$90,000":"58.5",
                "$100,000":"65",
                "$150,000":"97.5",
                "$200,000":"130",
                "$250,000":"162.5",
                "$300,000":"195",
                "$350,000":"227.5",
                "$400,000":"260",
                "$450,000":"290.33",
                "$500,000":"322.83",
                "$550,000":"355.33",
                "$600,000":"387.83"
            },
            "64<": {
                "$50,000":"N/A",
                "$60,000":"N/A",
                "$70,000":"N/A",
                "$80,000":"N/A",
                "$90,000":"N/A",
                "$100,000":"N/A",
                "$150,000":"N/A",
                "$200,000":"N/A",
                "$250,000":"N/A",
                "$300,000":"N/A",
                "$350,000":"N/A",
                "$400,000":"N/A",
                "$450,000":"N/A",
                "$500,000":"N/A",
                "$550,000":"N/A",
                "$600,000":"N/A"
            }
        }
    }
    var quickTerm = quickForm;
    //hide submit
    quickTerm.querySelector("button[type='submit']").style.display = "none";

    //when inputs change
    var performChange = function(event){
        //console.log("performing change");
        var selectedAnswers = [];
        var outputElement = quickTerm.querySelector(".frm_total_big input");
        var form = quickTerm
        var outputValue = "";
        //grab all selected answers
        for ( var i = 0; i < form.elements.length; i++ ) {
            var e = form.elements[i];
            if(e.name && e.type != "hidden" && e.name !="frm_verify" && e.value != ""){
                // console.log({
                //     'value': e.value,
                //     'type': e.type,
                //     'element': e
                // });
                if(
                    (e.selected || e.checked || e.classList.contains("hasDatepicker")) ||
                    (e.type != "select" && e.type != "radio")
                ){
                    // the "e" left over are all the answers selected in order

                    //go thru the calculation tree and compare values
                    //console.log(e.value);
                    selectedAnswers.push(e);
                }
            }
        }
        var performCalculation = function(tree, testKey){
            //console.log(selectedAnswers);
            for(var selectedElement of selectedAnswers){
                //console.log(selectedElement);
                //console.log({testKey, tree, "value": selectedElement.value})
                var treeKeys = Object.keys(tree)
                for(var key of treeKeys){
                    //console.log({key, "value" :selectedElement.value});
                    var valueToCheck = selectedElement.value
                    if(selectedElement.classList.contains("hasDatepicker")){
                        //console.log("found datepicker");

                        var userAge = getAge(valueToCheck)
                        if(0 <= userAge && userAge < 31){
                            //console.log("changes date value");
                            valueToCheck = ">31"
                        }
                        if(31 <= userAge && userAge < 40){
                            valueToCheck = "31-40"
                        }
                        if(41 <= userAge && userAge < 50){
                            valueToCheck = "41-50"
                        }
                        if(51 <= userAge && userAge < 55){
                            valueToCheck = "51-55"
                        }
                        if(56 <= userAge && userAge < 60){
                            valueToCheck = "56-60"
                        }
                        if(61 <= userAge && userAge < 65){
                            valueToCheck = "61-64"
                        }
                        if(65 <= userAge){
                            valueToCheck = "64<"
                        }
                    }
                    //console.log({"key":key, "value": valueToCheck, "element": selectedElement})
                    if(key == valueToCheck){
                        if(typeof tree[key] === "object"){
                            //console.log({"tree":tree[key], key});
                            performCalculation(tree[key], key)
                        }else{
                            if(tree[key] !== ""){
                                outputValue = tree[key];
                            }else{
                                outputValue = "N/A";
                            }
                            break;
                        }
                    }
                }
                if(outputValue !== ""){
                    break;
                }
            }
        }
        performCalculation(calculationTree, "first");
        //console.log(outputValue);
        //console.log(outputElement);
        outputElement.value = outputValue === "Null" || outputValue === "" ? "N/A" : "$" + outputValue;
        //console.log(selectedAnswers);
        //var queryString = kvpairs.join("&");
        //console.log(selectedAnswers);
        //find output element


    }

    //grab all inputs apply change
    quickTerm.addEventListener("change", performChange);
    quickTerm.querySelector(".frm_date").addEventListener("focus", function(){
        setTimeout(function(){
            var allDateLinks = document.getElementById("ui-datepicker-div").querySelectorAll("table a")
            //console.log(allDateLinks);
            for(var allDateLink of allDateLinks){
                allDateLink.addEventListener("click", function(){
                    //console.log("hello");
                    setTimeout(performChange, 100);
                    //performChange();
                })
            }
        },100)
    })


    var getAge = function(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        //console.log(age);
        return age;
    }

    var inputs = quickTerm.querySelectorAll(`input:not([disabled]):not([type='submit']),
        select:not([disabled]):not([type='submit'])`)
    //console.log(inputs);
}

//Term quick calculations
//grab container
var quickTerm = document.querySelector("#frm_form_10_container form");

if(quickTerm){
    applyQuickTerm(quickTerm);
}

