console.log("in retirement needs");
(function(){
    var applyretirementNeeds = function(quickForm){
        var selectedAnswers = {
            yearsUntilRetirement: 0,
            yearsRetirementIncomeInNecessary: 0,
            inflationRate: 0,
            preRetirement: 0,
            postRetirement: 0,
            grossAnnualIncomeRequired: 0,
            anticipatedSocialSecurityIncome: 0,
            annualPensionIncome: 0,
            retirementSavingsToday: 0,
            annualSavings: 0,
        };
        var retirementNeedsForm = quickForm;
        //hide submit
        retirementNeedsForm.querySelector("button[type='submit']").style.display = "none";
        //grab all inputs
        retirementNeedsForm.addEventListener("change", function(event){
            //var outputElement = quickDental.querySelector(".frm_total_big input");
            var form = retirementNeedsForm
            var selectedElements = [];
            //grab all selected answers
            for ( var i = 0; i < form.elements.length; i++ ) {
                var e = form.elements[i];
                //console.log(e)
                if(e.name && e.type != "hidden" && e.name !="frm_verify" && e.value != ""){
                    //console.log(e)
                    if(
                        (e.selected || e.checked) ||
                        (e.type != "select" && e.type != "radio")
                    ){
                        //console.log(e)
                        var label = e.labels[0].innerText;
                        //Years Until Retirement
                        if(label.startsWith("Years Until Retirement")){
                             selectedAnswers.yearsUntilRetirement = parseFloat(e.value.trim());  
                        }
                        //Years Retirement Income in Necessary
                        if(label.startsWith("Years Retirement Income Is Necessary")){
                            selectedAnswers.yearsRetirementIncomeInNecessary = parseFloat(e.value.trim());  
                        }
                        //Inflation Rate
                        if(label.startsWith("Inflation Rate")){
                            selectedAnswers.inflationRate = parseFloat(e.value.trim())/100;
                        }
                        //Pre-Retirement
                        if(label.startsWith("Pre-Retirement")){
                            selectedAnswers.preRetirement = parseFloat(e.value.trim())/100;
                        }
                        //Post-Retirement
                        if(label.startsWith("Post-Retirement")){
                            selectedAnswers.postRetirement = parseFloat(e.value.trim())/100;
                        }
                        //Gross Annual Income Required
                        if(label.startsWith("Gross Annual Income Required")){
                            selectedAnswers.grossAnnualIncomeRequired = parseFloat(e.value.trim()  );
                        }
                        //Anticipated Social Security Income
                        if(label.startsWith("Anticipated Annual Social Security Income")){
                            selectedAnswers.anticipatedSocialSecurityIncome = parseFloat(e.value.trim());  
                        }
                        //Annual Pension Income
                        if(label.startsWith("Annual Pension Income")){
                            selectedAnswers.annualPensionIncome = parseFloat(e.value.trim()  );
                        }
                        //Retirement Savings Today
                        if(label.startsWith("Current Retirement Savings")){
                            selectedAnswers.retirementSavingsToday = parseFloat(e.value.trim()  );
                        }
                        //Annual Savings
                        if(label.startsWith("Current Annual Retirement Contributions")){
                            selectedAnswers.annualSavings = parseFloat(e.value.trim());
                        }
                        
                        // the "e" left over are all the answers selected in order
                        
                        //go thru the calculation tree and compare values
                        //console.log(e.value);
                        //selectedAnswers.push(e);
                    }
                }
            }
            var performCalculation = function(){
                console.log(selectedAnswers);
                var todayOutput = {
                    annualIncomeDesired: 0,
                    anticipatedSocialSecurityIncome: 0,
                    annualPensionIncome: 0,
                    incomeNeededfromOtherSources: 0,
                    savingsNeededByRetirementDateToProvideAdditionalIncome: 0,
                    retirementSavingsToday: 0,
                    annualSavings: 0,
                    futureValueOfSavings: 0,
                    retirementIncomeNeedsMet: 0,
                }
                var futureOutput = {
                    annualIncomeDesired: 0,
                    anticipatedSocialSecurityIncome: 0,
                    annualPensionIncome: 0,
                    incomeNeededfromOtherSources: 0,
                    savingsNeededByRetirementDateToProvideAdditionalIncome: 0,
                    retirementSavingsToday: 0,
                    annualSavings: 0,
                    futureValueOfSavings: 0,
                    retirementIncomeNeedsMet: 0,
                }

                todayOutput.annualIncomeDesired = selectedAnswers.grossAnnualIncomeRequired;
                futureOutput.annualIncomeDesired = 
                    parseFloat(selectedAnswers.grossAnnualIncomeRequired) * 
                    ((1+parseFloat(selectedAnswers.inflationRate/100)) ** selectedAnswers.yearsUntilRetirement);
                console.log((1+parseFloat(selectedAnswers.inflationRate/100)));

                todayOutput.anticipatedSocialSecurityIncome = selectedAnswers.anticipatedSocialSecurityIncome;
                futureOutput.anticipatedSocialSecurityIncome = 
                    (selectedAnswers.retirementSavingsToday) * 
                    ((1+parseFloat(selectedAnswers.inflationRate/100)) ** selectedAnswers.yearsUntilRetirement);
                
                todayOutput.annualPensionIncome = selectedAnswers.annualPensionIncome;
                futureOutput.annualPensionIncome = 
                    (selectedAnswers.annualPensionIncome) * 
                    ((1+parseFloat(selectedAnswers.inflationRate/100)) ** selectedAnswers.yearsUntilRetirement);

                todayOutput.incomeNeededfromOtherSources = 
                    todayOutput.annualIncomeDesired - 
                    todayOutput.anticipatedSocialSecurityIncome - 
                    todayOutput.annualPensionIncome;
                futureOutput.incomeNeededfromOtherSources =
                    (todayOutput.incomeNeededfromOtherSources) * 
                    ((1+parseFloat(selectedAnswers.inflationRate/100)) ** selectedAnswers.yearsUntilRetirement);

                futureOutput.savingsNeededByRetirementDateToProvideAdditionalIncome =
                    (todayOutput.incomeNeededfromOtherSources * 
                    ((1+parseFloat(selectedAnswers.inflationRate/100)) ** selectedAnswers.yearsUntilRetirement)) *
                    ((1-((1+selectedAnswers.postRetirement/100)) ** (-selectedAnswers.yearsRetirementIncomeInNecessary)) /
                    (selectedAnswers.postRetirement/100));
                todayOutput.savingsNeededByRetirementDateToProvideAdditionalIncome = 
                        parseFloat(futureOutput.savingsNeededByRetirementDateToProvideAdditionalIncome) /
                        parseFloat((1 + parseFloat(selectedAnswers.inflationRate/100)) ** 
                        selectedAnswers.yearsUntilRetirement);
                
                todayOutput.retirementSavingsToday = selectedAnswers.retirementSavingsToday;
                futureOutput.retirementSavingsToday = selectedAnswers.retirementSavingsToday;

                todayOutput.annualSavings = selectedAnswers.annualSavings;
                futureOutput.annualSavings = selectedAnswers.annualSavings;

                todayOutput.futureValueOfSavings =
                        parseFloat(selectedAnswers.annualSavings * ((((1 + parseFloat(selectedAnswers.preRetirement/100)) **
                        selectedAnswers.yearsUntilRetirement) - 1) / 
                        (selectedAnswers.preRetirement/100))) + 
                        parseFloat(todayOutput.retirementSavingsToday * ((1 + parseFloat(selectedAnswers.preRetirement/100)) 
                        ** selectedAnswers.yearsUntilRetirement));
                futureOutput.futureValueOfSavings =
                        (todayOutput.retirementSavingsToday * ((1 + parseFloat(selectedAnswers.preRetirement/100)) ** 
                        selectedAnswers.yearsUntilRetirement)) + parseFloat(todayOutput.annualSavings) * (((1 + parseFloat(selectedAnswers.preRetirement/100)) ** 
                        selectedAnswers.yearsUntilRetirement -1) / parseFloat(selectedAnswers.preRetirement/100));

                todayOutput.retirementIncomeNeedsMet = 
                        (todayOutput.futureValueOfSavings > todayOutput.savingsNeededByRetirementDateToProvideAdditionalIncome) ?
                        "Yes" : "No";
                futureOutput.retirementIncomeNeedsMet =
                        (futureOutput.futureValueOfSavings > futureOutput.savingsNeededByRetirementDateToProvideAdditionalIncome) ?
                        "Yes" : "No";

                console.log({
                    today:todayOutput,
                    future:futureOutput
                })
                //Output Calculations
                var gebaTbody = document.getElementById("gebaTbody");
                if(!document.getElementById("gebaTbody")){
                    var newEl = document.createElement('div');
                    newEl.innerHTML = `
                        <h2>Output</h2>
                        <table>
                            <thead>
                                <tr>
                                    <td></td>
                                    <td>Today's Dollars</td>
                                    <td>Future Dollars</td>
                                </tr>
                            <tbody id="gebaTbody"></tbody>
                        </table>`;
                    document.getElementsByClassName('elementor-element-901efb0')[0].appendChild(newEl);
                    //console.log(gebaTbody);
                }
                
                var retirementNeedsRows = ""
                for (const item in todayOutput) {
                    var tr = "<tr>";

                    /* Verification to add the last decimal 0 */
                    // if (todayOutput[item].toString().substring(todayOutput[item].toString().indexOf('.'), todayOutput[item].toString().length) < 2){ 
                    //     todayOutput[item] += "0";
                    // }

                    /* Must not forget the $ sign */
                    //Convert camel case object name to readable
                    var addSpaces = item.replace( /([A-Z])/g, " $1" );
                    var finalResult = addSpaces.charAt(0).toUpperCase() + addSpaces.slice(1);
                    tr += "<td>" + finalResult + "</td>" + "<td>$" + todayOutput[item].toString() + "</td>" + "<td>$" + futureOutput[item].toString() + "</td></tr>";

                    /* We add the table row to the table body */
                    // console.log(tr);
                    retirementNeedsRows += tr;
                    
                }
                //Output geba calculations
                if(gebaTbody){
                    gebaTbody.innerHTML = retirementNeedsRows;
                }
            }
            performCalculation();
            //console.log(outputValue);
            //console.log(outputElement);
                //outputElement.value = outputValue === "N/A" ? outputValue : "$" + outputValue;
            //console.log(selectedAnswers);
            //var queryString = kvpairs.join("&");
            //console.log(selectedAnswers);
            //find output element
            
                
            });
            var inputs = retirementNeedsForm.querySelectorAll(`input:not([disabled]):not([type='submit']),
            select:not([disabled]):not([type='submit'])`)
            //console.log(inputs);
        }
        //grab container
        var retirementNeeds = document.querySelector("#frm_form_5_container form");
        if(retirementNeeds){
            applyretirementNeeds(retirementNeeds);
        }
        
        
    })();