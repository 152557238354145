
var applyQuickVision = function(quickForm){
    calculationTree = {
        "Standard":{
            "Member Only": {
                "Bi-Weekly":"4",
                "Monthly":"8.67",
                "Quarterly":"26",
                "Semi-Annually":"52",
                "Annually":"104"
            },
            "Member + One":{
                "Bi-Weekly":"7",
                "Monthly":"15.17",
                "Quarterly":"45.50",
                "Semi-Annually":"91",
                "Annually":"182"
            },
            "Member + Family":{
                "Bi-Weekly":"10",
                "Monthly":"21.67",
                "Quarterly":"65",
                "Semi-Annually":"130",
                "Annually":"260"
            }
        },
        "Enhanced":{
            "Member Only":{
                "Bi-Weekly":"5",
                "Monthly":"10.84",
                "Quarterly":"32.50",
                "Semi-Annually":"65",
                "Annually":"130"
            },
            "Member + One":{
                "Bi-Weekly":"8",
                "Monthly":"17.34",
                "Quarterly":"52",
                "Semi-Annually":"104",
                "Annually":"208"
            },
            "Member + Family":{
                "Bi-Weekly":"11",
                "Monthly":"23.84",
                "Quarterly":"71.50",
                "Semi-Annually":"143",
                "Annually":"286"
            }
        }
    }
    var quickVision = quickForm;
    //hide submit
    //quickVision.querySelector("button[type='submit']").style.display = "none";
    //grab all inputs
    quickVision.addEventListener("change", function(event){
        var orgSelect = document.getElementById("field_6xg03");
        //console.log(orgSelect.value);
        if(orgSelect.value !== "Central Intelligence Agency (CIA)" && 
        orgSelect.value !== "Defense Intelligence Agency (DIA)"){
            document.getElementById("field_ilsut-0").disabled = true;
        }else{
            document.getElementById("field_ilsut-0").disabled = false;
        }
        var selectedAnswers = [];
        var outputElement = quickVision.querySelector(".frm_total_big input");
        var form = quickVision
        var outputValue = "";
        //grab all selected answers
        for ( var i = 0; i < form.elements.length; i++ ) {
            var e = form.elements[i];
            if(e.name && e.type != "hidden" && e.name !="frm_verify" && e.value != ""){
                if(
                    (e.selected || e.checked) ||
                    (e.type != "select" && e.type != "radio")
                ){
                    // the "e" left over are all the answers selected in order

                    //go thru the calculation tree and compare values
                    //console.log(e.value);
                    selectedAnswers.push(e);
                }
            }
        }
        var performCalculation = function(tree, testKey){
            //console.log(selectedAnswers);
            for(var selectedElement of selectedAnswers){
                //console.log(selectedElement);
                //console.log({testKey, tree, "value": selectedElement.value})
                var treeKeys = Object.keys(tree)
                for(var key of treeKeys){
                    //console.log({key, "value" :selectedElement.value});
                    if(key === selectedElement.value){
                        if(typeof tree[key] === "object"){
                            //console.log({"tree":tree[key], key});
                            performCalculation(tree[key], key)
                        }else{
                            if(tree[key] !== ""){
                                outputValue = tree[key];
                            }else{
                                outputValue = "N/A";
                            }
                            break;
                        }
                    }
                }
                if(outputValue !== ""){
                    break;
                }
            }
        }
        performCalculation(calculationTree, "first");
        //console.log(outputValue);
        //console.log(outputElement);
        outputElement.value = outputValue === "N/A" || outputValue === "" ? "N/A" : "$" + outputValue;
        //console.log(selectedAnswers);
        //var queryString = kvpairs.join("&");
        //console.log(selectedAnswers);
        //find output element


    });
    var inputs = quickVision.querySelectorAll(`input:not([disabled]):not([type='submit']),
        select:not([disabled]):not([type='submit'])`)
    console.log(inputs);
}

//Vision quick calculations
//grab container
var quickVision = document.querySelector("#frm_form_9_container form");

if(quickVision){
    applyQuickVision(quickVision);
}

