var allRadios = document.querySelectorAll(".frm_radio input");

for (let index = 0; index < allRadios.length; index++) {
    allRadios[index].addEventListener('change',function(e){
        //find all related radio
        var radioContainer = allRadios[index].parentElement.parentElement.parentElement;
        console.log(radioContainer);
        var allLabels = radioContainer.querySelectorAll('label');
        console.log(allLabels);
        for (label of allLabels){
            console.log(label);
            if(!label.querySelector('input').checked){
                label.classList.remove("checked");
            }
        }
        if(allRadios[index].checked ===  true){
            allRadios[index].parentElement.classList.add("checked");
        }else{
            allRadios[index].parentElement.classList.remove("checked");
        }
    })
    
}

//grab all input that have before input field populated and use it to show text info
var allFormElements = document.querySelectorAll(".frm_form_field.frm_top_container");

for(element of allFormElements){
    var infoBox = element.querySelector(".frm_inline_box");
    var fieldLabel = element.querySelector(".frm_primary_label")
    if(infoBox){
        var iconEl = document.createElement('span')
        iconEl.classList.add("info-icon");
        iconEl.innerHTML = "i";
        fieldLabel.appendChild(iconEl);
        infoBox.style.display = "none";
    }
    fieldLabel.addEventListener('mouseover', function(event){
        var labelInfoBox = event.target.parentElement.querySelector(".frm_inline_box");
        if(labelInfoBox){
            labelInfoBox.style.display = "block";
            var labelCords = event.target.getBoundingClientRect();
            //console.log(labelCords);
            labelInfoBox.style.position = "fixed";
            labelInfoBox.style.top = (labelCords.top - 10) + "px";
            labelInfoBox.style.left = (labelCords.left + 30) +"px";
        }
    })
    fieldLabel.addEventListener('mouseout', function(event){
        var labelInfoBox = event.target.parentElement.querySelector(".frm_inline_box");
        if(labelInfoBox){
            labelInfoBox.style.display = "none";
        }
    })
}