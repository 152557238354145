console.log("in net worth");
(function(){
    var applyNetWorth = function(quickForm){
        //inputs
        var assetsAnswers = [
            'Checking',
            'Savings',
            'Certificates Of Deposit',
            'Money Marked Account',
            'Life Insurance Cash Value',
            'Home',
            'Cars',
            'Art & Collectibles',
            'Furnishings',
            'Jewelry',
            'Other(Personal)',
            'Mutual Funds',
            'Stocks',
            'Bonds',
            'Treasury Bills/Savings',
            'Pension Cash Value',
            '401(k)/TSP',
            'IRA',
            'Stock Options',
            'Other Tax-deferred'
        ];
        var liabilitiesAnswers = [
            'Outstanding Mortgage',
            'Home Equity Loan',
            'Car Loans',
            'Personal Loans',
            'Student Loans',
            'Loans against 401(k) / TSPs',
            'Credit Card Debt',
            'Other(Retirement)'

        ]
        
        var NetWorthForm = quickForm;
        //hide submit
        NetWorthForm.querySelector("button[type='submit']").style.display = "none";
        //grab all inputs
        NetWorthForm.addEventListener("change", function(event){
            //var outputElement = quickDental.querySelector(".frm_total_big input");
            var form = NetWorthForm
            
            var performCalculation = function(){
                //output boxes
                var outputBoxes = {
                    assets: 0,
                    liabilities: 0,
                    total: 0
                }
                //grab all selected answers
                for ( var i = 0; i < form.elements.length; i++ ) {
                    var e = form.elements[i];
                    if(e.name && e.type != "hidden" && e.name !="frm_verify" && e.value != ""){
                        //console.log(e)
                        if(
                            (e.selected || e.checked) ||
                            (e.type != "select" && e.type != "radio")
                        ){
                            //do asset total
                            var label = e.labels[0].innerText;
                            for (let iAssets = 0; iAssets < assetsAnswers.length; iAssets++) {
                                const element = assetsAnswers[iAssets];
                                if(label.startsWith(element)){
                                    outputBoxes.assets += parseFloat(e.value);
                                }
                            }
                            //do liabilities total
                            for (let iLiabilities = 0; iLiabilities < assetsAnswers.length; iLiabilities++) {
                                const element = liabilitiesAnswers[iLiabilities];
                                if(label.startsWith(element)){
                                    outputBoxes.liabilities += parseFloat(e.value);
                                }
                            }
                            //console.log(e.value);
                        }
                    }
                }

                var gebaTbody = document.getElementById("gebaTbody");
                var tableRows = `
                <tr>
                    <td>Total Assets</td>
                    <td> ${outputBoxes.assets} </td>
                </tr>
                <tr> 
                    <td>Total Liabilities</td>
                    <td> ${outputBoxes.liabilities} </td>
                </tr>
                <tr>
                    <td>Estimated Net Worth</td>
                    <td> ${outputBoxes.assets - outputBoxes.liabilities} </td>
                </tr>
                `;
                if(!gebaTbody){
                    var newEl = document.createElement('div');
                    newEl.innerHTML = `
                        <h2>Output</h2>
                        <table>
                            <thead>
                                <tr>
                                    <td></td>
                                    <td></td>
                                </tr>
                            <tbody id="gebaTbody">
                            </tbody>
                        </table>`;
                    document.getElementsByClassName('elementor-element-1168d82')[0].appendChild(newEl);

                    var table
                    //console.log(gebaTbody);
                }
                
                //Output geba calculations
                if(gebaTbody){
                    gebaTbody.innerHTML = tableRows;
                }
            }

            performCalculation();
            //console.log(outputValue);
            //console.log(outputElement);
                //outputElement.value = outputValue === "N/A" ? outputValue : "$" + outputValue;
            //console.log(selectedAnswers);
            //var queryString = kvpairs.join("&");
            //console.log(selectedAnswers);
            //find output element
            
                
            });
            var inputs = NetWorthForm.querySelectorAll(`input:not([disabled]):not([type='submit']),
            select:not([disabled]):not([type='submit'])`)
            //console.log(inputs);
        }
        //grab container
        var NetWorth = document.querySelector("#frm_form_6_container form");
        if(NetWorth){
            applyNetWorth(NetWorth);
        }
        
        
    })();