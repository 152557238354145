console.log("in longterm needs");
(function(){
    var applyLtdNeeds = function(quickForm){
        //inputs
        var selectedAnswers = {
            currentAnnualSalary: 0,
            biWeeklyNetPay: 0,
            addThriftSavingsPlan: 0,
            averageHighSalary: 0
        };
        //output boxes
        var outputBoxes = {
            biWeeklyGrossPay: 0,
            percentageOfGrossPayAfterTax: 0,
            firstMonthsOfDisability: 0,
            firstAfterTaxes: 0,
            subsequentMonthsOfDisability: 0,
            subAfterTaxes: 0,
            longTermDisabilityMonthlyBenefitYouMayNeed: 0
        }
        var LtdNeedsForm = quickForm;
        //hide submit
        LtdNeedsForm.querySelector("button[type='submit']").style.display = "none";
        //grab all inputs
        LtdNeedsForm.addEventListener("change", function(event){
            //var outputElement = quickDental.querySelector(".frm_total_big input");
            var form = LtdNeedsForm
            //grab all selected answers
            for ( var i = 0; i < form.elements.length; i++ ) {
                var e = form.elements[i];
                //console.log(e)
                //find all inputs
                if(e.name && e.type != "hidden" && e.name !="frm_verify" && e.value != ""){
                    //console.log(e)
                    if(
                        (e.selected || e.checked) ||
                        (e.type != "select" && e.type != "radio")
                    ){
                        //console.log(e)
                        var label = e.labels[0].innerText;
                        //Current Annual Salary
                        if(label.startsWith("Current Annual Salary")){
                            selectedAnswers.currentAnnualSalary = parseFloat(e.value.trim());  
                        }
                        //Bi-weekly Net Pay
                        if(label.startsWith("Bi-weekly Net Pay")){
                            selectedAnswers.biWeeklyNetPay = parseFloat(e.value.trim());  
                        }
                        //Add: Thrift Savings Plan (TSP) deduction & other benefits deducted
                        if(label.startsWith("Add: Thrift Savings Plan (TSP) deduction & other benefits deducted")){
                            selectedAnswers.addThriftSavingsPlan = parseFloat(e.value.trim());  
                        }
                        //Average High 3 Salary
                        if(label.startsWith("Average High 3 Salary")){
                            selectedAnswers.averageHighSalary = parseFloat(e.value.trim());  
                        }
                        
                        
                        
                        // the "e" left over are all the answers selected in order
                        
                        //go thru the calculation tree and compare values
                        //console.log(e.value);
                        //selectedAnswers.push(e);
                    }
                }
                //find all output boxes
                if(
                    e.name && e.type != "hidden" && e.name !="frm_verify"
                ){
                    if(
                        (e.selected || e.checked) ||
                        (e.type != "select" && e.type != "radio")
                    ){
                        var label = e.labels[0].innerText;
                        //Bi-weekly Gross Pay
                        if(label.startsWith("Bi-weekly Gross Pay")){
                            outputBoxes.biWeeklyGrossPay = e;  
                        }
                        //Percentage of Gross Pay after Tax
                        if(label.startsWith("Percentage of Gross Pay after Tax")){
                            outputBoxes.percentageOfGrossPayAfterTax = e;  
                        }
                        //First 12 Months of Disability
                        if(label.startsWith("First 12 Months of Disability")){
                            outputBoxes.firstMonthsOfDisability = e;  
                        }
                        //After Taxes(First 12 Months of Disability)
                        if(label.startsWith("After Taxes(First 12 Months of Disability)")){
                            outputBoxes.firstAfterTaxes = e;  
                        }
                        //Subsequent 12 Months of Disability
                        if(label.startsWith("Subsequent 12 Months of Disability")){
                            outputBoxes.subsequentMonthsOfDisability = e;  
                        }
                        //After Taxes(Subsequent 12 Months of Disability)
                        if(label.startsWith("After Taxes(Subsequent 12 Months of Disability)")){
                            outputBoxes.subAfterTaxes = e;  
                        }
                        //Long Term Disability Monthly Benefit You May Need
                        if(label.startsWith("Long Term Disability Monthly Benefit You May Need")){
                            outputBoxes.longTermDisabilityMonthlyBenefitYouMayNeed = e;  
                        }
                    }
                }
            }
            
                
            var performCalculation = function(){
                console.log({
                    input:selectedAnswers,
                    output:outputBoxes
                });
                outputBoxes.biWeeklyGrossPay.value = (selectedAnswers.currentAnnualSalary / 26).toFixed(2);

                outputBoxes.percentageOfGrossPayAfterTax.value = 
                    ((selectedAnswers.biWeeklyNetPay + selectedAnswers.addThriftSavingsPlan) / 
                    outputBoxes.biWeeklyGrossPay.value).toFixed(2);

                outputBoxes.firstMonthsOfDisability.value = 
                    ((selectedAnswers.averageHighSalary * .6) /12).toFixed(2);

                outputBoxes.firstAfterTaxes.value = 
                    (outputBoxes.firstMonthsOfDisability.value * outputBoxes.percentageOfGrossPayAfterTax.value).toFixed(2);

                outputBoxes.subsequentMonthsOfDisability.value = 
                    ((selectedAnswers.averageHighSalary * .4) / 12).toFixed(2);
                
                outputBoxes.subAfterTaxes.value = 
                    (outputBoxes.subsequentMonthsOfDisability.value * outputBoxes.percentageOfGrossPayAfterTax.value).toFixed(2);

                outputBoxes.longTermDisabilityMonthlyBenefitYouMayNeed.value = 
                    (((((selectedAnswers.currentAnnualSalary)/12) * .67) -
                    outputBoxes.subsequentMonthsOfDisability.value) > 7500) ?
                    7500 : 
                    ((((selectedAnswers.currentAnnualSalary)/12) * .67) - 
                    outputBoxes.subsequentMonthsOfDisability.value).toFixed(2);

            }

            performCalculation();
            //console.log(outputValue);
            //console.log(outputElement);
                //outputElement.value = outputValue === "N/A" ? outputValue : "$" + outputValue;
            //console.log(selectedAnswers);
            //var queryString = kvpairs.join("&");
            //console.log(selectedAnswers);
            //find output element
            
                
            });
            var inputs = LtdNeedsForm.querySelectorAll(`input:not([disabled]):not([type='submit']),
            select:not([disabled]):not([type='submit'])`)
            //console.log(inputs);
        }
        //grab container
        var LtdNeeds = document.querySelector("#frm_form_3_container form");
        if(LtdNeeds){
            applyLtdNeeds(LtdNeeds);
        }
        
        
    })();